import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';
import type {Candidate, FetchResponse} from 'utils/types';

export const candidateApi = createApi({
	reducerPath: 'candidateApi',
	baseQuery: baseQuery('/candidates'),
	endpoints: builder => ({
		getCandidateProfile: builder.query<FetchResponse<Candidate>, any>({
			query: params => ({
				url: 'profile',
				params
			})
		})
	})
});

export const {useLazyGetCandidateProfileQuery} = candidateApi;
