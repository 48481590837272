import {createApi} from '@reduxjs/toolkit/query/react';
import {IExamData} from 'pages/Exams/Exams';
import {Notification} from 'store/slices/notificationsSlice';
import baseQuery from 'utils/helpers/baseQuery';
import {
	Curriculum,
	AttendanceInfo,
	Commands,
	Discounts,
	FetchResponse,
	LessonData,
	Student
} from 'utils/types';

export const studentApi = createApi({
	reducerPath: 'studentApi',
	baseQuery: baseQuery('/students'),
	endpoints: builder => ({
		getStudentProfile: builder.query<FetchResponse<Student>, any>({
			query: () => ({
				url: 'profile'
			})
		}),
		getStudentApplications: builder.query({
			query: params => ({
				url: 'applications',
				params
			})
		}),
		getStudentOrders: builder.query<FetchResponse<Commands[]>, any>({
			query: params => ({
				url: 'commands',
				params
			})
		}),
		getStudentDiscounts: builder.query<FetchResponse<Discounts[]>, any>({
			query: params => ({
				url: 'getDiscounts',
				params
			})
		}),
		getStudentExams: builder.query<FetchResponse<IExamData[]>, any>({
			query: params => ({
				url: 'exams',
				params
			})
		}),
		getLessonsWeeks: builder.query({
			query: params => ({
				url: 'lessonWeek'
			})
		}),
		getStudentLessons: builder.query<FetchResponse<{data: LessonData[]}>, any>({
			query: params => {
				const weekID = params.lessonsWeekID;
				const newParams = Object.fromEntries(
					//eslint-disable-next-line @typescript-eslint/no-unused-vars
					Object.entries(params).filter(([_key, value]) => value !== null)
				);

				delete newParams.lessonsWeekID;

				return {
					url: `schedule/${weekID}`,
					params: newParams
				};
			}
		}),
		getStudentDailyLessons: builder.query<FetchResponse<LessonData[]>, any>({
			query: () => ({
				url: 'dailySchedule'
			})
		}),
		getStudentAttendance: builder.query<FetchResponse<Curriculum[]>, any>({
			query: params => ({
				url: 'lessonAttendanceStatistics',
				params
			})
		}),
		getStudentLessonPresentAbsentInfo: builder.query<
			FetchResponse<AttendanceInfo[]>,
			{semesterID: number; subjectID: number}
		>({
			query: params => ({
				url: 'lessonPresentAbsentInfo',
				params
			})
		}),
		regToConsultaion: builder.mutation<unknown, number>({
			query: examID => ({
				url: `consultation/${examID}`
			})
		}),
		addStudentApplication: builder.mutation<FetchResponse<any>, any>({
			query: body => ({
				url: 'applications',
				method: 'POST',
				body
			})
		}),
		getNotifications: builder.query<FetchResponse<Notification[]>, any>({
			query: () => ({
				url: 'notifications'
			})
		}),
		checkNotificationAsRead: builder.mutation<FetchResponse<any>, number>({
			query: notificationID => ({
				url: `notifications/${notificationID}`,
				method: 'PATCH'
			})
		}),
		selectOptionalSubjects: builder.mutation<FetchResponse<any>, number[]>({
			query: curriculumIDs => ({
				url: 'chooseOptionalSubjects/' + curriculumIDs,
				method: 'PATCH'
			})
		})
	})
});

export const {
	useLazyGetStudentProfileQuery,
	useGetStudentApplicationsQuery,
	useAddStudentApplicationMutation,
	useLazyGetStudentExamsQuery,
	useRegToConsultaionMutation,
	useGetStudentOrdersQuery,
	useLazyGetStudentLessonsQuery,
	useLazyGetLessonsWeeksQuery,
	useGetStudentDailyLessonsQuery,
	useGetStudentExamsQuery,
	useLazyGetStudentApplicationsQuery,
	useGetStudentDiscountsQuery,
	useGetStudentAttendanceQuery,
	useLazyGetStudentLessonPresentAbsentInfoQuery,
	useLazyGetNotificationsQuery,
	useCheckNotificationAsReadMutation,
	useSelectOptionalSubjectsMutation
} = studentApi;
