import {ColumnType} from 'antd/lib/table';

export interface Exams {}

export interface Candidate {
	name: string;
	lastname: string;
	middleName: string;
	id: number;
	email: string;
	registrationNumber: string;
	curriculumPayment: boolean;
	telephone: string;
	status: string;
	statusID: 1 | 2 | 3 | 4 | 5 | 6;
	exams: Exams[];
}

export interface MenuItem {
	creationDate: string;
	delete: 0 | 1;
	get: 0 | 1;
	id: number;
	itemOrderNum: number;
	itemParentID: number;
	lastModifiedDate: string | null;
	page: string;
	pageId: number;
	post: 0 | 1;
	privilegieId: number;
	privilegies_name: string;
	patch: 0 | 1;
	children: MenuItem[] | [];
	url: string;
}

export interface Student {
	id: number;
	candidateID: number;
	studentCode: string;
	studentAccountingNumber: number;
	email: string;
	name: string;
	lastName: string;
	middleName: string;
	name_hy: string;
	lastName_hy: string;
	middleName_hy: string;
	name_ru: string;
	middleName_ru: string;
	lastName_ru: string;
	name_en: string;
	middleName_en: string;
	lastName_en: string;
	creationDate: string;
	facultyID: number;
	semesterID: number;
	academicYearID: number;
	flowID: number;
	courseID: number;
	status: number;
	statusName: string;
	currentStatusName: string;
	currentStatusID: number;
	dob: string;
	nationalityID: number;
	nationalityName: string;
	genderType: string;
	admissionYear: number;
	facultyName: string;
	courseName: string;
	flowName: string;
	password: string;
	telephone: string;
	genderTypeID: number;
	pobID: number;
	pobName: string;
	citizenshipID: number;
	citizenshipName: string;
	communityID: number;
	registrationCountryID: number;
	registrationCountryName: string;
	registrationCountryOther: string;
	registrationCityVillage: string;
	registrationRegionID: number;
	registrationRegionName: string;
	registrationRegionOther: string;
	registrationAddress: string;
	dormitoryContract: string;
	residenceIsDifferentFromRegistration: boolean;
	residenceCountryID: number;
	residenceCountryOther: string;
	residenceCityVillage: string;
	residenceRegionID: number;
	residenceRegionOther: string;
	residenceAddress: string;
	militaryServiceID: number;
	militaryCommissariat: string;
	militaryServiceStartDate: string;
	militaryServiceEndDate: string;
	militaryServicePlace: string;
	passport: string;
	passportIssued: string;
	passportIssueDate: string;
	residenceCard: string;
	specialPassport: boolean;
	birthCertificateNumber: string;
	militaryService: string;
	motherNameSurname: string;
	motherTelEmail: string;
	motherProfession: string;
	motherCompany: string;
	fatherNameSurname: string;
	fatherTelEmail: string;
	fatherProfession: string;
	fatherCompany: string;
	orphanTypeID: number;
	ticketNumber: string;
	checkBookNumber: string;
	diplomaNumber: string;
	learningTypeID: number;
	learningTypeName: string;
	subjectsTeachingLanguageID: number;
	religionID: number;
	maritalStatusID: number;
	disabilityCategoryID: number;
	religionName: string;
	maritalStatusName: string;
	disabilityCategoryName: string;
	admissionCourseID: number;
	admissionLanguageExamID: number;
	admissionLanguageExamPassTypeID: number;
	examDetails: any;
	appealDetails: any;
	competitionTypeID: number;
	competitionTypeName: string;
	admissionFaculties: any[];
	optionalSubjects: any[];
	admissionOptionalSubjects: any[];
	admissionFiles: AdmissionFile[];
	payments: Payment[];
	groupIDs: number[];
	commands: Commands[];
	pastEducationInfo: PastEducationInfo[];
	studentSelectedSubjects: number[];
}

export interface AdmissionFile {
	id: number;
	creationDate: string;
	hy: string;
	typeID: number;
	approved: number;
	path: string;
}

export interface Payment {
	id: number;
	studentID: number;
	fileID: number;
	studentName: string;
	studentLastName: string;
	studentMiddleName: string;
	studentCode: string;
	studentAccountingNumber: string;
	paymentAmount: number;
	paymentTypeID: number;
	paymentPurposeID: number;
	createdByID: number;
	paymentType: string;
	paymentPurpose: string;
	paymentDate: string;
	path: string;
	status: string;
	creationDate: string;
	lastModifiedDate: string;
}

export interface Commands {
	id: number;
	typeID: number;
	commandSubTypeID: number;
	number: string;
	title: string;
	content: string;
	categoryID: number;
	examID: any;
	examName: any;
	effectOnDiscount: any;
	effectOnDiscountName: string;
	path: string;
	typeName: string;
	commandSubTypeName: string;
	categoryName: string;
	date: string;
	effectiveDate: string;
	comment: any;
	curriculumFacultyID: any;
	isCanceled: boolean;
	canceledCommandID: any;
	canceledCommandNumber: any;
	status: number;
	academicYearID: any;
	facultyID: any;
	semesterID: any;
	courseID: any;
	commandSemesterStatusID: any;
	tuitionFee: any;
}

export interface PastEducationInfo {
	id: number;
	name: string;
	startDate: number;
	endDate: number;
	studentID: number;
	educationTypeID: number;
	number: string;
	profession?: string;
	address: string;
	educationTypeName: string;
}

export interface LessonsWeek {
	id: number;
	academicYearID: number;
	academicYearName: string;
	semesterID: number;
	semesterName: string;
	courseID: number;
	courseName: string;
	weekStartDate: string;
	weekEndDate: string;
	cloningWeekID: number;
	creationDate: string;
	status: number;
	statusName: string;
}

export type TranslationFuncType = (key: string, val?: any) => string;

export interface Discounts {
	id: number;
	studentID: number;
	studentCode: string;
	courseID: number;
	facultyID: number;
	name: string;
	middleName: string;
	lastName: string;
	competitionTypeID: number;
	competitionTypeName: string;
	discountTypeID: number;
	discountTypeName: string;
	discount: number;
	academicYearID: number;
	academicYearName: string;
	semesterID: number;
	semesterName: string;
	path: string;
	comment: string;
	creationDate: string;
	status: number;
	statusName: string;
	groupsNames: string;
}

export interface ColumnsTypes extends ColumnType<any> {
	title: string;
	dataIndex: string;
	width?: number | string;
	hideColumn?: boolean;
	sortOrder?: any;
	inlineEditable?: ((record: any) => boolean) | boolean;
}

export interface Curriculum {
	id: number;
	academicYearID: number;
	academicYearName: string;
	semesterID: number;
	semesterName: string;
	courseID: number;
	courseName: string;
	subjectTypeID: number;
	subjectID: number;
	subjectName: string;
	flowID: number;
	practicalLessonHours: number;
	practicalLessonUsedHours: number;
	lectureLessonHours: number;
	lectureLessonUsedHours: number;
	totalUsedHours: number;
	totalHours: number;
	absentLessonCount: number;
	totalLessonCount: number;
	respectableAbsentCount: number;
	absentPercent: any;
	presentHours: number;
}

export interface AttendanceInfo {
	id: number;
	studentID: number;
	lessonID: number;
	presentName: string;
	creationDate: string;
	lastModifiedDate: any;
	respectableAbsentID: number | null;
	respectableAbsentName: string;
	present: boolean;
	studentEmail: string;
	studentName: string;
	studentLastName: string;
	studentMiddleName: string;
	studentCode: string;
	lecturerName: string;
	comment: string;
	path: string;
	lessonDate: string;
	lessonTime: string;
}

export interface LessonData {
	id: number;
	lessonWeekID: number;
	lessonWeekName: string;
	lessonStatusID: number;
	lessonStatusName: string;
	subjectTypeID: number;
	subjectTypeName: string;
	groupId: number;
	groupName: string;
	flowId: number;
	flowName: string;
	facultyID: number;
	lessonTypeID: number;
	lessonTypeName: string;
	lessonCategoryID: number;
	lessonCategoryName: string;
	classroomID: number;
	subjectID: number;
	replacedLecturer: boolean;
	subjectName: string;
	lessonDate: string;
	timeID: number;
	timeName: string;
	subjectsTeachingLanguageID: number;
	subjectsTeachingLanguageName: string;
	classroomName: string;
	lecturerID: number;
	lecturerName: string;
	substituteLecturerID: number;
	substituteLecturerName: string;
	substituteLecturerName_en: string;
	creationDate: string;
	status: number;
	statusName: string;
	facultiesName: string;
	present: string;
	presentStatus: boolean | null;
}

type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift' | number;
type ArrayItems<T extends Array<any>> = T extends Array<infer TItems> ? TItems : never;
export type FixedLengthArray<T extends any[]> = Pick<T, Exclude<keyof T, ArrayLengthMutationKeys>> & {
	[Symbol.iterator]: () => IterableIterator<ArrayItems<T>>;
};

export enum ROLES {
	STUDENT = 'STUDENT',
	CANDIDATE = 'CANDIDATE'
}

export type FetchResponse<T> = {
	status: string;
	message: string;
	data: T;
};
