import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';

export type Notification = {
	number: number;
	active: 1 | 0;
	content: string;
	creationDate: string;
	groupID: number;
	id: number;
	lastModifiedDate: string | null;
	path: string;
	status: 1 | 0;
	statusName: 'Ակտիվ';
	title: string;
	userID: number;
	userLastName: string;
	userMiddleName: string;
	userName: string;
};

type NotificationsState = {
	notifications: Notification[];
	recentNotifications: Notification[];
};

const slice = createSlice({
	name: 'settings',
	initialState: {
		notifications: [],
		recentNotifications: []
	} as NotificationsState,
	reducers: {
		setNotifications: (state, {payload}: PayloadAction<Notification[]>) => {
			const recentNotifications = payload.filter(notification => notification.active === 1);
			if (recentNotifications.length > 5) {
				recentNotifications.length = 5;
			}
			state.recentNotifications = recentNotifications;
			state.notifications = payload;
		},
		removeNotificationFromRecents: (state, {payload}: PayloadAction<number>) => {
			state.recentNotifications = state.recentNotifications.filter(
				notification => notification.id !== payload
			);
			state.notifications = state.notifications.map(notification => {
				if (notification.id === payload) {
					notification.active = 0;
				}
				return notification;
			});
		}
	}
});

export const {setNotifications, removeNotificationFromRecents} = slice.actions;

export default slice.reducer;

export const selectNotifications = (state: RootState) => state.notifications.notifications;
export const selectRecentNotifications = (state: RootState) => state.notifications.recentNotifications;
