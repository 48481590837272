import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {toast} from 'react-toastify';
import {RootState} from 'store';
import {logout} from 'store/slices/authSlice';

const _baseQuery = (baseURL?: string) =>
	fetchBaseQuery({
		baseUrl:
			(process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_API_URL_DEV
				: process.env.REACT_APP_API_URL_PROD) + (baseURL ? baseURL : ''),
		prepareHeaders: (headers, {getState}) => {
			const token = (getState() as RootState).auth.token;
			const lang = (getState() as RootState).settings.lang;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			if (lang) {
				headers.set('Accept-Language', lang);
			}
			return headers;
		}
	});

const baseQuery =
	(baseURL?: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
	async (args, api, extraOptions) => {
		const result = await _baseQuery(baseURL)(args, api, extraOptions);
		if (result.error && result.error.status === 401) {
			window.location.href = '/login';
			localStorage.removeItem('userCredentials');
			api.dispatch(logout());
		}
		if (result.error?.data && result.error.status !== 412) {
			if (typeof (result.error.data as {message?: string})?.message === 'string') {
				toast.warn((result.error.data as {message: string}).message);
			}
		}
		return result;
	};

export default baseQuery;
