import {Layout, Space} from 'antd';
import Header from 'components/Layout/Header';
import Sider from 'components/Layout/Sider';
import Loading from 'components/Loading';
import {Suspense, useEffect, useId, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {useLazyCheckAdmissionQuery} from 'store/services/auth';
import {useLazyGetCandidateProfileQuery} from 'store/services/candidate';
import {
	useLazyGetAppConfigsQuery,
	useLazyGetDictionaryQuery,
	useLazyGetFilesConfigsQuery
} from 'store/services/settings';
import {useLazyGetNotificationsQuery, useLazyGetStudentProfileQuery} from 'store/services/student';
import {
	selectIsAuthentificated,
	selectRole,
	setPartialCredentials,
	setUserInfo
} from 'store/slices/authSlice';
import {setNotifications} from 'store/slices/notificationsSlice';
import {
	selectLang,
	setCurrentAcademicYearID,
	setIsRegistrationOpen,
	setFilesConfigs,
	setAppConfigs
} from 'store/slices/settignsSlice';
import useMediaQuery from 'utils/hooks/useMediaQuery.hook';
import {privateRoutes, publicRoutes} from 'utils/routes';
import {ROLES} from 'utils/types';
import './App.less';

const {Content} = Layout;

const App = () => {
	const [loading, setLoading] = useState(true);
	const [getStudentProfile] = useLazyGetStudentProfileQuery();
	const [getCandidateProfile] = useLazyGetCandidateProfileQuery();
	const [checkAdmissionStatus] = useLazyCheckAdmissionQuery();
	const [getNotifications] = useLazyGetNotificationsQuery();
	const [getDictionary] = useLazyGetDictionaryQuery();
	const [getAppConfigs] = useLazyGetAppConfigsQuery();
	const [getFilesConfigs] = useLazyGetFilesConfigsQuery();
	const dispatch = useDispatch();
	const lang = useSelector(selectLang);
	const isAuthentificated = useSelector(selectIsAuthentificated);
	const userRole = useSelector(selectRole);
	const pageID = useId();
	const isMedium = useMediaQuery('(max-width: 992px)');

	useEffect(() => {
		const localStorageUserInfo = localStorage.getItem('userCredentials');

		if (!isAuthentificated && localStorageUserInfo) {
			const credentials = JSON.parse(localStorageUserInfo);
			dispatch(setPartialCredentials(credentials));
		}

		if (isAuthentificated) {
			if (userRole === ROLES.STUDENT) {
				getStudentProfile(null)
					.unwrap()
					.then(res => {
						dispatch(setUserInfo({user: res.data}));
					})
					.finally(() => {
						setLoading(false);
					});

				getNotifications(null)
					.unwrap()
					.then(notificationsResponse => {
						if (notificationsResponse?.data && notificationsResponse.data.length) {
							dispatch(setNotifications(notificationsResponse.data));
						}
					});
			}
			if (userRole === ROLES.CANDIDATE) {
				getCandidateProfile(null)
					.unwrap()
					.then(() => {})
					.finally(() => {
						setLoading(false);
					});
			}

			getDictionary('curriculumAcademicYears')
				.unwrap()
				.then(res => {
					const currentYear = new Date().getFullYear();
					const currentMonth = new Date().getMonth() + 1;

					const option = res.data.find((el: any) => {
						if (currentMonth >= 7) {
							return +el.name.substr(0, el.name.indexOf('-')) === currentYear;
						} else {
							return +el.name.substr(el.name.indexOf('-') + 1) === currentYear;
						}
					});

					if (option) {
						dispatch(setCurrentAcademicYearID(option.id));
					}
				});
		} else {
			checkAdmissionStatus(null)
				.unwrap()
				.then(() => {
					dispatch(setIsRegistrationOpen(true));
				})
				.catch(() => {
					dispatch(setIsRegistrationOpen(false));
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [isAuthentificated, lang]);

	useEffect(() => {
		setLoading(true);
		Promise.all([
			getAppConfigs()
				.unwrap()
				.then(res => {
					dispatch(setAppConfigs(res.data));
				}),
			getFilesConfigs()
				.unwrap()
				.then(res => {
					dispatch(setFilesConfigs(res.data));
				})
		]).finally(() => {
			setLoading(false);
		});
	}, []);

	if (loading) return <Loading />;

	if (!isAuthentificated) {
		return (
			<Layout>
				<Content className="content content-signedOut">
					<Suspense fallback={<Loading />}>
						<Routes>
							{publicRoutes.map((i, idx) => (
								<Route
									path={i.path}
									element={i.element}
									key={`page-public-${idx}-${pageID}`}
								/>
							))}
							<Route path="*" element={<Navigate to="/login" />} />
						</Routes>
					</Suspense>
				</Content>
			</Layout>
		);
	}
	return (
		<Space direction="vertical" style={{width: '100%', height: '100%'}} size={[0, 48]}>
			<Layout>
				{!isMedium && <Sider />}
				<Layout>
					<Header />
					<Content className="content">
						<Suspense fallback={<Loading />}>
							<Routes>
								{privateRoutes.map((i, idx) => {
									if (!i.roles.includes(userRole)) return null;
									return (
										<Route
											path={i.path}
											element={i.element}
											key={`page-private-${idx}-${pageID}`}
										/>
									);
								})}
								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>
						</Suspense>
					</Content>
				</Layout>
			</Layout>
		</Space>
	);
};

export default App;
