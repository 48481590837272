import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';
import eLOGO from 'assets/eUniversityLogo.png';

export type Lang = 'en' | 'ru' | 'hy';

const LOGO_TYPE_ID = 1;

export interface ISystemFiles {
	id: number;
	path: string;
	creationDate: string;
	typeID: number;
	typeName: string;
}

export interface IAppConfigs {
	id: number;
	universityName_hy: string;
	universityName_en: string;
	universityName_ru: string;
	footerText: string;
	loginPageBackground: any;
	background1: string;
	background2: string;
	background3: any;
	buttonBackground1: string;
	buttonBackground2: string;
	buttonBackground3: string;
	creationDate: string;
	lastModifiedDate: string;
}

type SettingsState = {
	lang: Lang;
	isRegistrationOpen: boolean;
	currentAcademicYearID: number;
	appConfigs: IAppConfigs;
	filesConfigs: ISystemFiles[];
};

const slice = createSlice({
	name: 'settings',
	initialState: {
		lang:
			(['en', 'ru', 'hy'].includes(localStorage.getItem('lang') as string) &&
				localStorage.getItem('lang')) ||
			'hy',
		isRegistrationOpen: false
	} as SettingsState,
	reducers: {
		setLang: (state, {payload}: PayloadAction<Lang>) => {
			localStorage.setItem('lang', payload);
			state.lang = payload;
		},
		setIsRegistrationOpen: (state, {payload}: PayloadAction<boolean>) => {
			state.isRegistrationOpen = payload;
		},
		setCurrentAcademicYearID: (state, {payload}: PayloadAction<number>) => {
			state.currentAcademicYearID = payload;
		},
		setAppConfigs: (state, {payload}: PayloadAction<IAppConfigs>) => {
			document.title =
				payload[`universityName_${localStorage.getItem('lang') as 'hy' | 'en' | 'ru'}`] ||
				payload.universityName_hy ||
				'eUniversity';

			state.appConfigs = payload;
		},
		setFilesConfigs: (state, {payload}: PayloadAction<ISystemFiles[]>) => {
			const favicon = payload.find(file => file.typeID === 5);

			let link = document.querySelector("link[rel~='icon']");

			if (!link) {
				link = document.createElement('link');
				(link as any).rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}

			if (favicon !== undefined) {
				(link as any).href =
					(process.env.NODE_ENV === 'development'
						? process.env.REACT_APP_API_URL_DEV
						: process.env.REACT_APP_API_URL_PROD) +
					'/' +
					favicon.path;
			} else {
				(link as any).href = eLOGO;
			}

			state.filesConfigs = payload;
		}
	}
});

export const {setLang, setIsRegistrationOpen, setCurrentAcademicYearID, setAppConfigs, setFilesConfigs} =
	slice.actions;

export default slice.reducer;

export const selectLang = (state: RootState) => state.settings.lang;
export const selectRegistrationStatus = (state: RootState) => state.settings.isRegistrationOpen;
export const selectAcademicYear = (state: RootState) => state.settings.currentAcademicYearID;
export const selectAppConfigs = (state: RootState) => state.settings.appConfigs;
export const selectUniversityName = (state: RootState) =>
	state.settings?.appConfigs?.[`universityName_${state.settings.lang}`];
export const selectFilesConfigs = (state: RootState) => state.settings?.filesConfigs;
export const selectLogo = (state: RootState) =>
	state.settings?.filesConfigs?.find(file => file.typeID === LOGO_TYPE_ID)?.path || '';
