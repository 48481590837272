import {Badge, Button, Divider, Drawer, Dropdown, Layout, Menu, MenuProps, Modal} from 'antd';
import Logo from 'assets/logo_white.svg';
import {
	Book1,
	CalendarTick,
	CloseCircle,
	DirectboxNotif,
	DiscountShape,
	HambergerMenu,
	Home,
	Notification,
	TaskSquare,
	Teacher
} from 'iconsax-react';
import {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {logout, selectCurrentUser, selectRole} from 'store/slices/authSlice';
import {selectRecentNotifications} from 'store/slices/notificationsSlice';
import {Lang, selectLang, selectLogo, selectUniversityName, setLang} from 'store/slices/settignsSlice';
import {getImage} from 'utils/helpers/getImage';
import useMediaQuery from 'utils/hooks/useMediaQuery.hook';
import {privateRoutes} from 'utils/routes';
import i18n from 'utils/translations/i18n';
import {Student} from 'utils/types';
import './Header.less';

const {Header} = Layout;
const {confirm} = Modal;

const LANGUAGES = {
	hy: 'AM',
	en: 'EN',
	ru: 'RU'
};

interface HeaderProps {
	type?: 'SignedIn' | 'SignedOut';
}

const AppHeader = ({type = 'SignedIn'}: HeaderProps) => {
	const {t} = useTranslation();
	const location = useLocation();
	const lang = useSelector(selectLang);
	const userRole = useSelector(selectRole);
	const userInfo = useSelector(selectCurrentUser) as Student;
	const universityName = useSelector(selectUniversityName);
	const universityLogo = useSelector(selectLogo);
	const [currentMenu, setCurrentMenu] = useState<string>(location.pathname);
	const [collapsed, setCollapsed] = useState(true);
	const isMedium = useMediaQuery('(max-width: 992px)');
	const imageRef = useRef<HTMLImageElement>(null);

	const admissionFiles = userInfo?.admissionFiles || [];
	const userImage = admissionFiles?.find(i => i.typeID == 4)?.path || '';

	const recentNotifications = useSelector(selectRecentNotifications);

	const logoRef = useRef<HTMLImageElement>(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const menuItems: MenuProps['items'] = useMemo(
		() =>
			[
				{
					label: t('menu.dashboard'),
					icon: <Home size={24} variant="Bold" color="white" />,
					key: '/'
				},
				{
					label: t('menu.admission'),
					icon: <TaskSquare size={24} variant="Bold" color="white" />,
					key: '/admission'
				},
				{
					label: t('menu.exams'),
					icon: <Teacher size={24} variant="Bold" color="white" />,
					key: '/exams'
				},
				{
					label: t('menu.lessons'),
					icon: <Book1 size={24} variant="Bold" color="white" />,
					key: '/lessons'
				},
				{
					label: t('menu.applications'),
					icon: <Teacher size={24} variant="Bold" color="white" />,
					key: '/applications'
				},
				{
					label: t('menu.orders'),
					icon: <DirectboxNotif size={24} variant="Bold" color="white" />,
					key: '/orders'
				},
				{
					label: t('menu.discounts'),
					icon: <DiscountShape color="#ffffff" variant="Bold" />,
					key: '/discounts'
				},
				{
					label: t('menu.curriculum'),
					icon: <CalendarTick color="#ffffff" variant="Bold" />,
					key: '/curriculum'
				}
			].filter(item => {
				return privateRoutes.find(route => route.path === item.key)?.roles.includes(userRole);
			}),
		[lang, userRole]
	);

	const onMenuSelect: MenuProps['onClick'] = e => {
		if (currentMenu === e.key) return;
		setCurrentMenu(e.key);
		navigate(e.key);
		setCollapsed(true);
	};

	const headerTitle = useMemo(
		() =>
			privateRoutes.find(i => {
				const routePath = i.path.includes(':')
					? i.path.substring(0, i.path.indexOf(':') - 1)
					: i.path;

				const currentPath = !isNaN(
					+location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
				)
					? location.pathname.substring(0, location.pathname.lastIndexOf('/'))
					: location.pathname;

				return routePath === currentPath;
			})?.title || privateRoutes.find(i => i.path === '/')?.title,
		[location.pathname, i18n.language]
	);

	const handleSelectLang = (lang: Lang) => {
		dispatch(setLang(lang));
		i18n.changeLanguage(lang);
	};

	const handleLogout = () => {
		dispatch(logout());
	};

	const showConfirmLogout = () => {
		confirm({
			title: t('messages.logoutTitle'),
			icon: null,
			closable: true,
			closeIcon: <CloseCircle size="20" color="black" />,
			content: t('messages.logoutContent'),
			cancelText: t('messages.logoutCancel'),
			okText: t('messages.logoutConfirm'),
			onOk: handleLogout,
			centered: true,
			className: 'modal'
		});
	};

	if (type === 'SignedOut')
		return (
			<Header className="header header-signedOut">
				<div className="logo-container">
					<img
						src={getImage(universityLogo, logoRef, Logo)}
						alt="Logo"
						onClick={() => {
							navigate('/');
						}}
						ref={logoRef}
						onError={() => {
							if (logoRef.current !== null) {
								logoRef.current.src = Logo;
							}
						}}
					/>
					<span>{universityName || 'eUniversity'}</span>
				</div>
				<div></div>
				<div></div>
			</Header>
		);

	return (
		<Header className="header">
			{isMedium ? (
				<div className="collapse-container">
					<Button
						onClick={() => setCollapsed(prev => !prev)}
						type="text"
						icon={<HambergerMenu size={24} color={isMedium ? 'white' : 'rgba(0, 0, 0, 0.88)'} />}
					/>
					<span className="header-title">{t(headerTitle as string)}</span>
				</div>
			) : (
				<span className="header-title">{t(headerTitle as string)}</span>
			)}
			<div className="header-actions">
				<Dropdown
					trigger={['click']}
					placement="bottom"
					overlay={() => (
						<Menu className="lang-dropdown">
							<Menu.Item key="hy">
								<span onClick={() => handleSelectLang('hy')}>{LANGUAGES.hy}</span>
							</Menu.Item>
							<Menu.Item key="en">
								<span onClick={() => handleSelectLang('en')}>{LANGUAGES.en}</span>
							</Menu.Item>
							<Menu.Item key="ru">
								<span onClick={() => handleSelectLang('ru')}>{LANGUAGES.ru}</span>
							</Menu.Item>
						</Menu>
					)}>
					<a className="lang-link" onClick={e => e.preventDefault()}>
						{LANGUAGES[lang]}
					</a>
				</Dropdown>
				<Dropdown
					trigger={['click']}
					placement="bottomLeft"
					overlay={() => (
						<Menu className="notifications-dropdown">
							{recentNotifications.length ? (
								recentNotifications.map(item => (
									<Menu.Item key={item.id}>
										<div
											className="notification-item"
											onClick={() =>
												navigate('/notifications', {
													state: {defaultActiveKeys: [item.id]}
												})
											}>
											<span className="notification-item_title">{item.title}</span>
											<span className="notification-item_content">{item.content}</span>
										</div>
									</Menu.Item>
								))
							) : (
								<Menu.Item key="empty">
									<div>{t('notifications.noData')}</div>
								</Menu.Item>
							)}
							<Divider style={{margin: '5px 0'}} />
							<Menu.Item key="all">
								<a onClick={() => navigate('/notifications')}>
									<div style={{textAlign: 'center', width: '100%'}}>{t('global.all')}</div>
								</a>
							</Menu.Item>
						</Menu>
					)}>
					<Badge status="success" dot={!!recentNotifications.length}>
						<a className="lang-link" onClick={e => e.preventDefault()}>
							<Notification />
						</a>
					</Badge>
				</Dropdown>
				<Dropdown
					trigger={['click']}
					placement="bottomLeft"
					overlay={() => (
						<Menu className="profile-dropdown">
							<Menu.Item key="profile">
								<span onClick={() => navigate('/profile')}>
									<span>{t('menu.profile')}</span>
								</span>
							</Menu.Item>
							<Menu.Item key="logout">
								<span onClick={showConfirmLogout}>
									<span>{t('menu.exit')}</span>
								</span>
							</Menu.Item>
						</Menu>
					)}>
					<img
						ref={imageRef}
						src={getImage(userImage, imageRef)}
						alt="profile"
						className="avatar"
					/>
				</Dropdown>
			</div>
			<Drawer
				className="sider"
				open={!collapsed}
				onClose={() => setCollapsed(() => true)}
				placement="left"
				width={'100%'}>
				<Menu
					className="menu"
					mode="vertical"
					selectedKeys={[currentMenu]}
					onClick={onMenuSelect}
					items={menuItems}
				/>
			</Drawer>
		</Header>
	);
};

export default AppHeader;
