import React from 'react';
import {FixedLengthArray, ROLES} from './types';

const Login = React.lazy(() => import('pages/Login'));
const Register = React.lazy(() => import('pages/Register'));
const Confirm = React.lazy(() => import('pages/Confirm'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));

const Profile = React.lazy(() => import('pages/Profile'));

const Dashboard = React.lazy(() => import('pages/Dashboard'));

const Exams = React.lazy(() => import('pages/Exams'));

const Lessons = React.lazy(() => import('pages/Lessons'));

const Curriculum = React.lazy(() => import('pages/Curriculum'));

const Applications = React.lazy(() => import('pages/Applications'));
const NewApplication = React.lazy(() => import('pages/Applications/NewApplication'));
const ViewApplication = React.lazy(() => import('pages/Applications/ViewApplication'));

const Orders = React.lazy(() => import('pages/Orders'));
const ViewOrder = React.lazy(() => import('pages/Orders/ViewOrder'));

const Discounts = React.lazy(() => import('pages/Discounts'));
const ViewDiscounts = React.lazy(() => import('pages/Discounts/ViewDiscount'));

const Notifications = React.lazy(() => import('pages/Notifications'));

export type PublicRouteConstructor = {
	path: string;
	element: JSX.Element;
};

export type PrivateRouteConstructor = {
	title: string;
	path: string;
	element: JSX.Element;
	roles: FixedLengthArray<[ROLES.STUDENT | ROLES.CANDIDATE, (ROLES.STUDENT | ROLES.CANDIDATE)?]>;
};

export const publicRoutes: PublicRouteConstructor[] = [
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/register',
		element: <Register />
	},
	{
		path: '/forgot_password',
		element: <ForgotPassword />
	},
	{
		path: '/reset_password',
		element: <ResetPassword />
	},
	{
		path: '/confirm',
		element: <Confirm />
	}
];

export const privateRoutes: PrivateRouteConstructor[] = [
	{
		title: 'menu.dashboard',
		path: '/',
		element: <Dashboard />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.exams',
		path: '/exams',
		element: <Exams />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.lessons',
		path: '/lessons',
		element: <Lessons />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.applications',
		path: '/applications',
		element: <Applications />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.newApplication',
		path: '/applications/new',
		element: <NewApplication />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.singleApplication',
		path: '/applications/view/:id',
		element: <ViewApplication />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.orders',
		path: '/orders',
		element: <Orders />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.singleOrder',
		path: '/orders/view/:id',
		element: <ViewOrder />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.discounts',
		path: '/discounts',
		element: <Discounts />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.singleDiscount',
		path: '/discounts/view/:id',
		element: <ViewDiscounts />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.curriculum',
		path: '/curriculum',
		element: <Curriculum />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.profile',
		path: '/profile',
		element: <Profile />,
		roles: [ROLES.STUDENT]
	},
	{
		title: 'menu.notifications',
		path: '/notifications',
		element: <Notifications />,
		roles: [ROLES.STUDENT]
	}
];
