import avatar from 'assets/avatar.png';
import {RefObject} from 'react';

export const getImage = (
	path: string | undefined,
	imageRef: RefObject<HTMLImageElement>,
	defaultImage?: string
) => {
	if (imageRef.current !== null) {
		imageRef.current.onerror = () => {
			if (imageRef.current !== null) {
				imageRef.current.src = defaultImage || avatar;
			}
		};
	}

	return path
		? (process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_API_URL_DEV
				: process.env.REACT_APP_API_URL_PROD) +
				'/' +
				path
		: avatar;
};
