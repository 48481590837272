import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';
import {authApi} from './services/auth';
import {studentApi} from './services/student';
import {candidateApi} from './services/candidate';
import authSlice from './slices/authSlice';
import settingsSlice from './slices/settignsSlice';
import {settingsApi} from './services/settings';
import notificationsSlice from './slices/notificationsSlice';

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[studentApi.reducerPath]: studentApi.reducer,
		[candidateApi.reducerPath]: candidateApi.reducer,
		[settingsApi.reducerPath]: settingsApi.reducer,
		auth: authSlice,
		settings: settingsSlice,
		notifications: notificationsSlice
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([
			authApi.middleware,
			studentApi.middleware,
			candidateApi.middleware,
			settingsApi.middleware
		])
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
