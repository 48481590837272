import {Button, Layout, Menu, MenuProps, Tooltip} from 'antd';
import Logo from 'assets/logo_white.svg';
import {
	Book1,
	CalendarTick,
	DirectboxNotif,
	DiscountShape,
	HambergerMenu,
	Home,
	TaskSquare,
	Teacher
} from 'iconsax-react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {selectRole} from 'store/slices/authSlice';
import {selectLang, selectLogo, selectUniversityName} from 'store/slices/settignsSlice';
import {getImage} from 'utils/helpers/getImage';
import {useLocalStorage} from 'utils/hooks';
import useMediaQuery from 'utils/hooks/useMediaQuery.hook';
import {privateRoutes as routes} from 'utils/routes';
import './Sider.less';

const {Sider} = Layout;

const SiderMenu = () => {
	const {t} = useTranslation();
	const location = useLocation();
	const [collapsed, setCollapsed] = useLocalStorage('sider_collapsed', false);
	const [currentMenu, setCurrentMenu] = useState<string>(location.pathname);
	const isMedium = useMediaQuery('(max-width: 992px)');
	const lang = useSelector(selectLang);
	const userRole = useSelector(selectRole);
	const universityName = useSelector(selectUniversityName);
	const universityLogo = useSelector(selectLogo);
	const navigate = useNavigate();

	const menuItems: MenuProps['items'] = useMemo(
		() =>
			[
				{
					label: t('menu.dashboard'),
					icon: <Home size={24} variant="Bold" color="white" />,
					key: '/'
				},
				{
					label: t('menu.admission'),
					icon: <TaskSquare size={24} variant="Bold" color="white" />,
					key: '/admission'
				},
				{
					label: t('menu.exams'),
					icon: <Teacher size={24} variant="Bold" color="white" />,
					key: '/exams'
				},
				{
					label: t('menu.lessons'),
					icon: <Book1 size={24} variant="Bold" color="white" />,
					key: '/lessons'
				},
				{
					label: t('menu.applications'),
					icon: <Teacher size={24} variant="Bold" color="white" />,
					key: '/applications'
				},
				{
					label: t('menu.orders'),
					icon: <DirectboxNotif size={24} variant="Bold" color="white" />,
					key: '/orders'
				},
				{
					label: t('menu.discounts'),
					icon: <DiscountShape color="#ffffff" variant="Bold" />,
					key: '/discounts'
				},
				{
					label: t('menu.curriculum'),
					icon: <CalendarTick color="#ffffff" variant="Bold" />,
					key: '/curriculum'
				}
			].filter(item => {
				return routes.find(route => route.path === item.key)?.roles.includes(userRole);
			}),
		[lang, userRole]
	);

	const logoRef = useRef<HTMLImageElement>(null);

	const onMenuSelect: MenuProps['onClick'] = e => {
		if (currentMenu === e.key) return;
		setCurrentMenu(e.key);
		navigate(e.key);
	};

	useEffect(() => {
		if (isMedium && !collapsed) {
			setCollapsed(() => true);
		}
	}, [isMedium]);

	useEffect(() => {
		if (!menuItems.map(i => i?.key).includes(location.pathname)) {
			setCurrentMenu(location.pathname);
		}
	}, [location, menuItems]);

	return (
		<Sider trigger={null} collapsible collapsed={collapsed} className="sider" width={210}>
			<div className={`sider-header-wrapper ${collapsed && 'sider-header-wrapper-center'}`}>
				{!collapsed && (
					<div className="logo-container">
						<Tooltip title={universityName}>
							<img
								src={getImage(universityLogo, logoRef, Logo)}
								alt="Logo"
								ref={logoRef}
								onClick={() => {
									navigate('/');
								}}
								width={42}
								onError={e => {
									logoRef.current?.setAttribute('src', Logo);
								}}
							/>
						</Tooltip>
					</div>
				)}
				<div className="collapse-container">
					<Button
						onClick={() => setCollapsed(prev => !prev)}
						type="text"
						icon={<HambergerMenu size={24} />}
					/>
				</div>
			</div>
			<Menu
				className="menu"
				mode="vertical"
				selectedKeys={[
					currentMenu.slice(
						0,
						currentMenu.slice(1).indexOf('/') !== -1
							? currentMenu.slice(1).indexOf('/') + 1
							: undefined
					) || currentMenu
				]}
				onClick={onMenuSelect}
				items={menuItems}
			/>
		</Sider>
	);
};

export default SiderMenu;
