import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {Candidate, ROLES, Student} from 'utils/types';
import type {RootState} from 'store';

type AuthState =
	| {
			user: Partial<Candidate>;
			token: string;
			id: number;
			role: ROLES.CANDIDATE;
	  }
	| {
			user: Partial<Student>;
			token: string;
			id: number;
			role: ROLES.STUDENT;
	  };

const slice = createSlice({
	name: 'auth',
	initialState: {user: null, token: null, id: null} as unknown as Partial<AuthState>,
	reducers: {
		setUserInfo: (state, {payload: {user}}: PayloadAction<{user: AuthState['user']}>) => {
			state.user = user;
		},
		logout: state => {
			localStorage.removeItem('userCredentials');

			state.role = undefined;
			state.token = undefined;
			state.user = undefined;
		},
		setPartialCredentials: (state, {payload: {token, role, id}}: PayloadAction<Partial<AuthState>>) => {
			state.role = role;
			state.token = token;
			state.id = id;
		},
		setUserSelectedOptionalSubjects: (
			state,
			{payload: {selectedOptionalSubjects}}: PayloadAction<{selectedOptionalSubjects: number[]}>
		) => {
			if (state.user && state?.role === ROLES.STUDENT) {
				state.user.studentSelectedSubjects = selectedOptionalSubjects;
			}
		}
	}
});

export const {setUserInfo, logout, setPartialCredentials, setUserSelectedOptionalSubjects} = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectIsAuthentificated = (state: RootState) => !!state.auth.token;
export const selectRole = (state: RootState) => state.auth.role;
export const selectAuthInfo = (state: RootState) => state.auth;
export const selectStudentSelectedOptionalSubjects = (state: RootState) =>
	(state.auth.user as Partial<Student>)?.studentSelectedSubjects;
