import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';
import {FetchResponse, ROLES} from 'utils/types';

export interface UserResponse {
	token: string;
	id: number;
	student: boolean;
}

export interface LoginRequest {
	email: string;
	password: string;
}

interface ForgotPasswordRequest {
	email: string;
}

interface ResetPasswordRequest {
	token: string;
	password: string;
	c_password: string;
}

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQuery('/c/auth/'),
	endpoints: builder => ({
		login: builder.mutation<FetchResponse<UserResponse>, LoginRequest>({
			query: body => ({
				url: 'login',
				method: 'POST',
				body
			})
		}),
		register: builder.mutation({
			query: body => ({
				url: 'register',
				method: 'POST',
				body
			})
		}),
		confirm: builder.query<unknown, string>({
			query: token => ({
				url: `confirm/${token}`
			})
		}),
		forgotPassword: builder.mutation<unknown, ForgotPasswordRequest>({
			query: body => ({
				url: 'forgotPassword',
				method: 'POST',
				body
			})
		}),
		resetPassword: builder.mutation<unknown, ResetPasswordRequest>({
			query: body => ({
				url: `resetPassword/${body.token}`,
				method: 'POST',
				body: {
					password: body.password,
					c_password: body.c_password
				}
			})
		}),
		checkAdmission: builder.query({
			query: () => ({
				url: 'checkAdmission'
			})
		})
	})
});

export const {
	useLoginMutation,
	useLazyCheckAdmissionQuery,
	useForgotPasswordMutation,
	useLazyConfirmQuery,
	useRegisterMutation,
	useResetPasswordMutation
} = authApi;
