import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import localResources from './resources';

const resources = {
	hy: {
		translation: localResources.hy
	},
	ru: {
		translation: localResources.ru
	},
	en: {
		translation: localResources.en
	}
};

i18n.use(initReactI18next).init(
	{
		lng:
			(['hy', 'en', 'ru'].includes(localStorage.getItem('lang') as string) &&
				localStorage.getItem('lang')) ||
			'hy',
		fallbackLng: ['hy'],
		debug: false,
		resources,
		returnEmptyString: false,
		interpolation: {
			escapeValue: false
		},
		parseMissingKeyHandler: key => `${key} IS MISSING IN TRANSLATIONS`,
		react: {
			useSuspense: false
		}
	},
	(err, t) => {
		if (err) return console.error(err);
	}
);

export default i18n;
