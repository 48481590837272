import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';
import {ROLES} from 'utils/types';

export const settingsApi = createApi({
	reducerPath: 'settingsApi',
	baseQuery: baseQuery('/settings'),
	endpoints: builder => ({
		getDictionary: builder.query<any, string>({
			query: dictionary => ({
				url: dictionary
			})
		}),
		getByCustompath: builder.query<any, string>({
			query: url => ({
				url
			})
		}),
		getAppConfigs: builder.query<any, void>({
			query: () => ({
				url: '../system/configs'
			})
		}),
		getFilesConfigs: builder.query<any, void>({
			query: () => ({
				url: '../system/files'
			})
		})
	})
});

export const {
	useLazyGetDictionaryQuery,
	useLazyGetByCustompathQuery,
	useLazyGetAppConfigsQuery,
	useLazyGetFilesConfigsQuery
} = settingsApi;
